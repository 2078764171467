import { SxProps } from '@mui/material'
import { BaseSchema } from 'yup'

import { ChannelType, UserPermission, UserRoles, SettingsUpdate } from '@app/graphql'

export interface CreateAdminValues {
  email: string
  password?: string
  firstName: string
  lastName: string
}


export interface CreateTipsterValues {
  email: string
  password?: string
  displayName: string
  country: string
  language: string
  altName: string
  deal: number
  avatarUrl?: string
  file?: File
  altAvatar?: string
  altFile?: File
  role?: UserRoles
  channels: string[]
  bio: string
  backgroundFile?: File
}

export interface CreateUserValues {
  email: string
  password?: string
  displayName: string
  country: string
  role?: UserRoles
}

export interface CreateAdvertValues {
  id?: string
  title: string
  imageFile?: File
  imageUrl?: string
  placement?: string[]
  advertTarget: AdvertTarget[]
  startDate: Date
  endDate: Date
  referral: string
  visible: boolean
  active: boolean
}

export interface AdvertTarget {
  channelId: string
  countryIds: string[]
}

export interface CreateChannelValues {
  title: string
  logoUrl?: string
  logoFile?: File
  country: string
  type: ChannelType
  anonymity: boolean
  share: boolean
  deal: number
  fee: number
  trial: number
  usersLimit: number
  backgroundFile?: File
  settings?: SettingsUpdate
}

export interface CreateServiceValues {
  internalName: string
  title: string
  subTitle: string
  ctaTitle: string
  ctaSubTitle: string
  paymentMethods: string[]
  autoRenewal: boolean
  expirationAlert: boolean
  status: string
  tipsterId: string
  channelId: string
  email: string
  dateStart: Date
  imageLink?: string
  imageFile?: File
  info: string
}

export interface CreateSubscriptionValues {
  serviceId: string
  priceId?: string
  durationId?: string
  withNewPrice?: boolean
  priceValue?: number
  currencyId?: string
  withNewDuration?: boolean
  durationValue?: number
}

export interface CreateFreeTrialValues {
  freeTrialPeriod?: number
}

export interface PermissionItem {
  id: UserPermission
  name: string
}

export interface SelectElementType {
  value: string
  label?: string
}

export interface CreateAffiliateLinkValues {
  internalName: string
  tipsterId: string
  channelId: string
  deal?: number
  expirationDate: Date
  payPerHead?: number
  pphCurrency?: string
  useLimit?: number
}

export enum FormFieldType {
  text = 'text',
  textEditor = 'textEditor',
  number = 'number',
  select = 'select',
  image = 'image',
  datepicker = 'datepicker',
  checkbox = 'checkbox',
  country = 'country',
  advertTarget = 'advertTarget',
  switch = 'switch',
  file = 'file'
}

export interface FormFieldDataType {
  name: string
  label: string
  type: FormFieldType
  initValue: any
  validation?: BaseSchema
  sxProps?: SxProps
  required?: boolean
  multiple?: boolean
  multiline?: boolean
  elements?: SelectElementType[]
  image?: {
    link?: string
    alt: string
    width: number
    height: number
    circle?: boolean
  }
  file?: {
    readText?: boolean
  }
  dependent?: {
    field: string
    value: boolean
  }
}
